<template>
    <div id="products-list">

        <b-modal centered size="sm" ref="add-to-cart-modal" :title="item.description" hide-footer>
            <b-form-input placeholder="La quantité demandée" ref="qte-input" v-model="requestedQte" type="number"
                @keyup.enter="handleOk"></b-form-input>
            <div class="d-block text-center">
                <b-button variant="relief-success" class="mt-1" @click="handleOk">Valider</b-button>
            </div>
        </b-modal>

        <b-modal centered ref="rating-modal" :title="item.description" hide-footer>
            <b-form-rating style="width: 100%;" v-model="currentUserRating" size="lg" inline variant="warning" />
            <div class="d-block text-center">
                <b-button variant="relief-success" class="mt-1" @click="ratingClick">Évaluer</b-button>
            </div>
        </b-modal>

        <b-modal centered ref="rating-history-modal" :title="item.description" hide-footer>
            <b-media v-for="(item, index) in ratingHistory" :key="index">
                <template #aside>
                    <b-avatar :key="ratingsProfilePic" :src="item.profilePic" size="32"
                        :text="item.fullName.substring(0, 2)" variant="light-primary" />
                </template>
                <b-row>
                    <b-col cols="8">
                        <b-link class="font-weight-bold d-block text-nowrap">
                            {{ item.fullName }}
                        </b-link>
                        <small class="text-muted">{{ item.updatedAt }} | </small>
                        <small class="text-muted">{{ item.role }} </small>
                    </b-col>
                    <b-col class="text-right" cols="4">
                        <b-form-rating no-border v-model="item.rating" readonly variant="warning" />
                    </b-col>
                </b-row>
            </b-media>
        </b-modal>


        <b-overlay :show="showLoading" spinner-variant="primary" :key="productKey">

            <b-alert variant="danger" :show="itemNotFound" class="m-1">
                <div class="alert-body">
                    <feather-icon icon="XSquareIcon" class="mr-50" />
                    Nous regrettons de vous informer que cet article n'est pas vendable auprès du grossiste sélectionné
                    '{{ companyName }}'. Veuillez choisir un autre grossiste.
                </div>
            </b-alert>

            <b-alert variant="warning" :show="outOfStock" class="m-1">
                <div class="alert-body">
                    <feather-icon icon="AlertOctagonIcon" class="mr-50" />
                    Désolé, cet article est actuellement en rupture de stock auprès du grossiste sélectionné
                    '{{ companyName }}'. Veuillez choisir un autre grossiste.
                </div>
            </b-alert>


            <b-card no-body>
                <b-card-body>
                    <div class="mt-md-0 mt-2 mb-1">
                        <b-row>
                            <b-col cols="12" sm="6">
                                <h3 class="text-primary invoice-logo">
                                    {{ item.description }}
                                </h3>
                            </b-col>
                            <b-col cols="12" sm="6" class="text-right d-none d-md-block" v-if="isPharmacy">
                                <b-button size="sm" variant="outline-warning" pill class="mr-50"
                                    @click="fav ? $store.dispatch('app-favorites/DELETE_FAVORITE', item) : $store.dispatch('app-favorites/ADD_FAVORITE', item); fav = !fav;">
                                    <feather-icon icon="HeartIcon" :class="{ 'fill-current': fav }" class="mr-50" />
                                    <span> {{ fav ? 'Retirer des Favoris' : 'Ajouter aux Favoris' }}</span>
                                </b-button>
                                <b-button size="sm" variant="success" pill :disabled="item.inventory <= 0"
                                    @click="addToCart">
                                    <feather-icon icon="ShoppingCartIcon" class="mr-50" />
                                    <span class="align-middle">Ajouter à la Commande Courante</span>
                                </b-button>
                            </b-col>
                            <b-col cols="12" sm="6" class="text-center d-md-none" v-if="isPharmacy">
                                <b-button size="sm" variant="success" pill :disabled="item.inventory <= 0"
                                    @click="addToCart">
                                    <feather-icon icon="ShoppingCartIcon" class="mr-50" />
                                    <span class="align-middle">Ajouter à la Commande Courante</span>
                                </b-button>
                            </b-col>
                        </b-row>
                    </div>

                    <hr>

                    <b-row class="my-2">

                        <b-col cols="12" md="5">

                            <div v-if="item.images == undefined" class="shimmer-line"
                                style="animation: shimmer 2.5s linear infinite;height: 100%;"></div>
                            <b-carousel v-else controls indicators :interval="2000">
                                <b-carousel-slide v-if="item.images.length == 0" class="carousel-image"
                                    :img-src="require('@/assets/images/default-img.svg')" />
                                <b-carousel-slide style="border-style: groove ! important;"
                                    v-for="(item, index) in item.images" :key="index"
                                    class="carousel-image carousel-image-exist" :img-src="$serverpath + item"/>
                            </b-carousel>
                        </b-col>

                        <b-col cols="12" md="7">


                            <h5 class="mt-md-0 mt-2 mb-1">
                                Famille :
                                <code style="color : #28c76f ">{{ item.familyDescription }}</code>
                            </h5>
                            <h5 class="mt-md-0 mt-2 mb-1">
                                Sous-Famille :
                                <code style="color : #28c76f ">{{ item.subFamilyDescription }}</code>
                            </h5>
                            <h5 class="mt-md-0 mt-2 mb-1">
                                Fournisseur :
                                <code style="color : #28c76f ">{{ item.vendorDescription }}</code>
                            </h5>
                            <h5 class="mt-md-0 mt-2 mb-1">
                                Prix H.T :
                                <code :class="item.priceHT == undefined ? 'shimmer-line' : ''"
                                    style="color : #7367f0;animation: shimmer 5s linear infinite;"
                                    :style="item.priceHT == undefined ? 'padding-right: 240px;' : ''">{{ item.priceHT }}</code>
                            </h5>
                            <h5 class="mt-md-0 mt-2 mb-1">
                                Prix T.T.C :
                                <code :class="item.priceTTC == undefined ? 'shimmer-line' : ''"
                                    style="color : #7367f0;animation: shimmer 5s linear infinite;"
                                    :style="item.priceTTC == undefined ? 'padding-right: 240px;' : ''">{{ item.priceTTC
                            }}</code>
                            </h5>
                            <h5 class="mt-md-0 mt-2 mb-1">
                                Cartonnage :
                                <code :class="item.packaging == undefined ? 'shimmer-line' : ''"
                                    style="color : #7367f0;animation: shimmer 5s linear infinite;"
                                    :style="item.packaging == undefined ? 'padding-right: 240px;' : ''">{{ item.packaging
                            }}</code>
                            </h5>


                            <hr>

                            <h5 class="mt-md-0 mt-2 mb-1">
                                <div v-if="ratingValue == undefined" class="shimmer-line"
                                    style="animation: shimmer 2.5s linear infinite;height: 30px; width:50%"></div>
                                <div v-else>
                                    <b-form-rating class="ml-0" no-border v-model="ratingValue" inline color="#ff8800"
                                        precision="2" readonly />
                                    <b-link @click="$refs['rating-modal'].show();">
                                        évaluer
                                    </b-link>
                                    <b-link class="ml-2" @click="$refs['rating-history-modal'].show();">
                                        voir les évaluations
                                    </b-link>
                                </div>
                            </h5>

                            <hr>


                            <div v-if="item.indications == undefined" class="shimmer-line"
                                style="animation: shimmer 2.5s linear infinite;height: 150px;padding-right: 350px;">
                            </div>
                            <b-card-text v-else-if="item.indications != ''">
                                <quill-editor disabled :options='editorOption'
                                    v-model='item.indications'></quill-editor>
                            </b-card-text>

                        </b-col>
                    </b-row>
                </b-card-body>
            </b-card>


            <b-card v-if="comments == undefined">
                <div class="shimmer-line" style="animation: shimmer 2.5s linear infinite;height: 50px; width: 10%;">
                </div>
                <br>
                <div class="shimmer-line" style="animation: shimmer 2.5s linear infinite;height: 70px;"></div>
                <br>
                <div class="shimmer-line" style="animation: shimmer 2.5s linear infinite;height: 50px; width: 10%;">
                </div>
                <br>
                <div class="shimmer-line" style="animation: shimmer 2.5s linear infinite;height: 70px;"></div>
            </b-card>

            <b-card v-else>
                <b-col cols="12" class="mt-2">
                    <h6 class="section-label">
                        LAISSEZ UN COMMENTAIRE
                    </h6>
                    <b-card>
                        <b-row>
                            <b-col cols="12">
                                <b-form-group class="mb-2">
                                    <b-form-textarea name="textarea" rows="2"
                                        placeholder="écrivez un commentaire ici ..." v-model="comment.text" />
                                </b-form-group>
                            </b-col>
                            <b-col cols="12">
                                <b-form-checkbox v-model="comment.isAnonymous" name="checkbox-1" class="mb-2">
                                    afficher le commentaire comme anonyme, mon nom ne sera pas affiché .
                                </b-form-checkbox>
                            </b-col>
                            <b-col cols="12">
                                <b-button @click="saveComment" variant="primary">
                                    Poster
                                </b-button>
                            </b-col>
                        </b-row>
                    </b-card>
                </b-col>
                <b-card v-for="(item, index) in comments" :key="index" style="border: 5px solid #f6f8fa;">
                    <b-media no-body>
                        <b-media-aside class="mr-75">
                            <b-avatar :src="item.profilePic" :key="commentsProfilePic"
                                :text="item.isAnonymous ? 'An' : item.fullName.substring(0, 2)" size="38" />
                        </b-media-aside>
                        <b-media-body>
                            <h6 class="font-weight-bolder mb-25">
                                {{ item.isAnonymous ? 'Anonyme' : item.fullName }}
                            </h6>
                            <small class="text-muted">{{ item.createdAt }} | {{ item.role }} </small>
                            <hr>
                            <b-card-text>
                                {{ item.comment }}
                            </b-card-text>
                        </b-media-body>
                    </b-media>
                </b-card>

            </b-card>




        </b-overlay>

    </div>
</template>

<script>

import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { isPharmacy } from "@/auth/utils";
import 'quill/dist/quill.core.css';
import 'quill/dist/quill.snow.css';
import 'quill/dist/quill.bubble.css';
import {
    quillEditor
} from 'vue-quill-editor';
import ContentWithSidebar from '@/@core/layouts/components/content-with-sidebar/ContentWithSidebar.vue';

export default {

    metaInfo() {
        return {
            title: "Détails du produit - PharmaConnect",
            meta: [
                {
                    vmid: "description",
                    name: "description",
                    content:
                        "Découvrez les détails complets du produit " + this.product.description + " sur PharmaConnect.",
                },
                {
                    name: "robots",
                    content: "index, follow",
                },
                {
                    property: "og:title",
                    content: "Détails du produit - PharmaConnect",
                },
                {
                    property: "og:description",
                    content: "Découvrez les détails du produit " + this.product.description + " et commandez-le sur PharmaConnect. Profitez d'une présentation détaillée avec des informations telles que le dosage, le prix et la disponibilité.",
                },
                {
                    property: "og:image",
                    content: (this.product.defImage == undefined || this.product.defImage == null || this.product.defImage == '') ? require('@/assets/images/default.jpg') : this.$serverpath + this.product.defImage
                },
                {
                    property: "og:url",
                    content: "https://www.pharmaconnect.net/products-list",
                },
                {
                    property: "og:type",
                    content: "product",
                },
                {
                    name: "twitter:card",
                    content: "summary_large_image",
                },
                {
                    name: "twitter:title" + this.product.description,
                    content: "Détails du produit - PharmaConnect" + this.product.indications,
                },
                {
                    name: "twitter:description",
                    content: "Découvrez les détails du produit " + this.product.description + " et commandez-le sur PharmaConnect. Présentation complète, prix et disponibilité.",
                },
                {
                    name: "twitter:image",
                    content: (this.product.defImage == undefined || this.product.defImage == null || this.product.defImage == '') ? require('@/assets/images/default.jpg') : this.$serverpath + this.product.defImage
                },
            ],
            link: [
                {
                    rel: "canonical",
                    href: "https://www.pharmaconnect.net/products-list",
                },
            ],
            script: [
                // Structured Data using Schema.org
                {
                    type: "application/ld+json",
                    json: {
                        "@context": "https://schema.org",
                        "@type": "Product",
                        "name": this.product.description,
                        "description": "Détails du produit " + this.product.description + " sur PharmaConnect, incluant le dosage, la présentation, et la classe thérapeutique.",
                        "brand": {
                            "@type": "Brand",
                            "name": "PharmaConnect"
                        },
                        "offers": {
                            "@type": "Offer",
                            "priceCurrency": "TND",
                            "availability": "InStock",
                            "url": "https://www.pharmaconnect.net/products-list"
                        }
                    },
                },
            ],
        };
    },

    components: {
        quillEditor,
    },
    props: {
        product: {
            type: Object,
            required: true
        },
    },
    data() {
        return {
            ratingsProfilePic: 0,
            commentsProfilePic: 0,
            comments: undefined,
            comment: {
                text: "",
                isAnonymous: false
            },
            ratingHistory: [],
            currentUserRating: 0,
            ratingValue: undefined,
            editorOption: {
                theme: 'bubble',
                readOnly: true
            },
            fav: false,
            isPharmacy: isPharmacy(),
            relatedProducts: [],
            requestedQte: '',
            item: {},
            itemNotFound: false,
            outOfStock: false,
            companyName: "",
            productKey: 0,
            showLoading: false,
        }
    },
    async created() {
        let fav = await this.$store.dispatch('app-favorites/FIND_FAVORITE', this.product.code);
        this.fav = fav != undefined;
        this.item = this.product;
        this.fetchData();
    },
    methods: {
        async saveComment() {
            this.comments = undefined;
            let response = await this.$http.get("_item/commentItem/" + this.item.code + "/" + this.comment.text + "/" + this.comment.isAnonymous);
            this.comment = {
                text: "",
                isAnonymous: false
            };
            this.comments = response.data;
            this.loadProfilesPic();
            this.comments.forEach(async element => {
                if (!element.isAnonymous) {
                    let response = await this.$http.get("account/getUserProfilePic/" + element.username);
                    element.profilePic = response.data;
                    this.commentsProfilePic += 1;
                }
            });
        },
        async ratingClick() {
            this.ratingValue = undefined;
            this.$refs['rating-modal'].hide();
            let response = await this.$http.get("_item/ratingItem/" + this.item.code + "/" + this.currentUserRating);
            ContentWithSidebar.ratingHistory = response.data;
            this.calcRating(response.data);
            this.ratingHistory.forEach(async element => {
                let response = await this.$http.get("account/getUserProfilePic/" + element.username);
                element.profilePic = response.data;
                this.ratingsProfilePic += 1;
            });
        },
        async fetchData() {
            this.item.inventory = 0;
            let response = await this.$http.get("_item/itemCompanyInfo/" + this.item.code);
            // console.log(response);
            if (response.data.status == 200) {
                this.companyName = response.data.result.company;
                this.item.priceHT = response.data.result.priceHT + " TND";
                this.item.priceTTC = response.data.result.priceTTC + " TND";
                this.item.packaging = response.data.result.packaging;
                this.item.inventory = response.data.result.inventory;
                this.outOfStock = response.data.result.inventory <= 0;
                this.itemStatus = "";
                this.item.indications = response.data.result.indications == null ? '' : response.data.result.indications;
                this.item.images = response.data.result.images;
                this.showLoading = false;
                const rootEle = document.documentElement
                rootEle.scrollTo({
                    top: 0,
                    behavior: 'smooth',
                })
            } else {
                this.companyName = response.data.result.company;
                this.itemNotFound = true;
                this.item.priceHT = "___";
                this.item.priceTTC = "___";
                this.item.packaging = "___";
                this.item.images = response.data.result.images;
                this.item.indications = response.data.result.indications == null ? '' : response.data.result.indications;
                this.showLoading = false;
                const rootEle = document.documentElement
                rootEle.scrollTo({
                    top: 0,
                    behavior: 'smooth',
                });
            }
            this.comments = response.data.result.comments;
            this.calcRating(response.data.result.ratings);
            this.loadProfilesPic();
        },
        async loadProfilesPic() {
            this.ratingHistory.forEach(async element => {
                let response = await this.$http.get("account/getUserProfilePic/" + element.username);
                element.profilePic = response.data;
                this.ratingsProfilePic += 1;
            });
            this.comments.forEach(async element => {
                if (!element.isAnonymous) {
                    let response = await this.$http.get("account/getUserProfilePic/" + element.username);
                    element.profilePic = response.data;
                    this.commentsProfilePic += 1;
                }
            });
        },
        calcRating(ratings) {
            this.ratingHistory = ratings;
            const totalRatings = ratings.length;
            if (totalRatings == 0) {
                this.ratingValue = 0;
            } else {
                const totalRatingPoints = ratings.reduce((sum, rating) => sum + rating.rating, 0);
                this.ratingValue = totalRatingPoints / totalRatings;
            }
            this.productKey += 1;
        },
        relatedProductClick(item) {
            this.showLoading = true;
            this.item = item;
        },
        async findRelatedItems() {
            this.relatedProducts = this.$store.getters["app-items/geItemsPARA"].filter(item => item.subFamilyDescription == this.item.subFamilyDescription).slice(0, 20);
        },
        handleOk() {
            this.$refs['add-to-cart-modal'].hide();
            this.showLoading = true;
            this.item["Qte"] = this.requestedQte;
            this.$store.dispatch('app-items/GET_INVENTORY', this.item).then((response) => {
                this.showLoading = false;
                this.$toast.clear();
                this.$toast({
                    component: ToastificationContent,
                    props: response,
                },
                    {
                        timeout: 2000
                    });
                if (!["warning", "danger"].includes(response.variant))
                    this.$emit('itemAddedToCart');
            }).catch((error) => {
                this.showLoading = false;
                console.log(error);
            });
        },
        addToCart() {
            this.requestedQte = "";
            this.$refs['add-to-cart-modal'].show();
            setTimeout(() => {
                this.$refs["qte-input"].focus();
            }, 400);
        },
    }
}
</script>

<style lang="scss" scoped>
@import '@core/scss/vue/libs/swiper.scss';
@media (max-width: 768px) {
    .card {
        background-color: initial;
        box-shadow: initial;
    }

    .card-body {
        padding: 0px;
    }
}
</style>

<style lang="scss">
@media (min-width: 768px) {
    .carousel-image img {
        // border-style: groove ! important;
        width: 700px ! important;
        height: 450px ! important;
    }

    .custom-center {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }

    .carousel-image-exist img {
        border: 3px solid #f6f8fa;
    }
}


.shimmer-line {
    margin-left: 10px;
    height: 200px;
    background: linear-gradient(to right, #f3f3f3 8%, #e5e5e5 18%, #f3f3f3 33%);
    background-size: 800px 104px;
}

@keyframes shimmer {
    0% {
        background-position: -468px 0;
    }

    100% {
        background-position: 468px 0;
    }
}
</style>

<style lang="scss">
@import "~@core/scss/base/pages/app-ecommerce-details.scss";
</style>
